import React,{useState,useEffect} from 'react'
import "./FetchData.css";
export default function Fetchdata() {
    const [list,setList]=useState([]);
    useEffect(()=>{
        fetch('https://jsonplaceholder.typicode.com/users')
      .then(response => response.json())
      .then(users => setList(users))
    },[])
   console.log(list)
  return (
    <div className='container'>
        <ul className='table'>
            <h2>New Users</h2>
            {list.map((user,i)=>(
                <li key={i} className='item'>{user.id} {user.name}</li>
            ))}
        </ul>

        <div className="purple"></div>
        <div className="pink"></div>
      
    </div>
  )
}
