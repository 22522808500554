import Fetchdata from "./fetchdata/Fetchdata";


function App() {
  return (
    <div className="App">
     <Fetchdata/>
    </div>
  );
}

export default App;
